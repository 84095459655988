<button
	[attr.data-tracking-id]="trackingId ? trackingId : null"
	class="rounded-input font-bold {{ styleClasses() }} {{
		sizeClasses()
	}} outline-2 outline-purple-500"
	[ngClass]="{
		'w-full': isFullWidthSig()
	}"
	[disabled]="isDisabled"
	[type]="buttonType()"
>
	<ng-content></ng-content>
</button>
